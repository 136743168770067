const appId = process.env.SQUARE_APPLICATION_ID;
const locationId = null;

async function initializeCard(payments) {
  const card = await payments.card();
  await card.attach('#card-container');
  return card;
}

async function createPayment(token) {
  const body = JSON.stringify({
    locationId,
    sourceId: token,
  });

  const paymentResponse = await fetch('/payment', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  if (paymentResponse.ok) {
    return paymentResponse.json();
  }

  const errorBody = await paymentResponse.text();
  throw new Error(errorBody);
}

async function tokenize(paymentMethod) {
  const tokenResult = await paymentMethod.tokenize();
  if (tokenResult.status === 'OK') {
    return tokenResult.token;

  } else {
    let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
    if (tokenResult.errors) {
      errorMessage += ` and errors: ${JSON.stringify(
        tokenResult.errors
      )}`;
    }
    //throw new Error(errorMessage);
	return errorMessage
  }
}

async function loadPaymentForm () {
  if(!window.Square){
    console.error('Square.js not loaded');
    window.setTimeout(loadPaymentForm,300);
    return;
  }
  let payments;
  try {
    payments = window.Square.payments(appId, locationId);
  } catch(e) {
    const statusContainer = document.getElementById(
      'payment-status-container'
    );
    statusContainer.className = 'missing-credentials';
    statusContainer.style.visibility = 'visible';
    console.error(e);

    return;
  }

  let card;
  try {
    card = await initializeCard(payments);
  } catch (e) {
    console.error('Initializing Card failed', e);
    return;
  }

  // Checkpoint 2.
  async function handlePaymentMethodSubmission(event, paymentMethod) {
    event.preventDefault();
    try {
        // disable the submit button as we await tokenization and make a payment request.
        cardButton.disabled = true;
        const token = await tokenize(paymentMethod);
    document.getElementById('card-nonce').value = token;
    } catch (e) {
    console.error(e.message);
      cardButton.disabled = false;
    }
  }

  const cardButton = document.getElementById('card-button');
  cardButton.addEventListener('click', async function (event) {
    await handlePaymentMethodSubmission(event, card);
  document.getElementById('payment-form').submit();
  });
}

document.addEventListener('turbolinks:load', function () {
  if (document.getElementById('square-ad-payment')) {
    window.setTimeout(function() {
      if(window.Square){
        loadPaymentForm();
      } else {
        console.error('Square.js not loaded');
        window.setTimeout(loadPaymentForm,300)
      }
    },100);
  }

});